import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
  gap: 25px;
  row-gap: 20px;
  margin-top: 5em;
  padding-bottom: 30px;
  /* background:purple; */

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, minmax(400px, 1fr)); /* Two columns for screen width <= 1280px */
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(1, minmax(400px, 1fr)); /* One column for screen width <= 876px */
  }
`;

const TestimonialBlock = styled.div`
  border: 30px solid transparent;
  border-image: url('/img/border.png') 30 stretch;
  width: 380px;
  height: 440px;
  padding: 10px;
  text-align: left;
  color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    width: 80%;
    height: 100%;
  }
`;


const Title = styled.h3`
  color: #E1B76D;
  cursor:pointer;
  margin-bottom: 10px;

  @media (max-width: 850px) {
   font-size: 100%;
  }
`;

const Text = styled.p`
  color: #fff;
`;

const Logo = styled.img`
  width: 70%;
  max-width: 500px;
  height: auto;
  margin-top: 10em;

  @media (max-width: 430px) {
    width: 60%;
  }
`;

const Img = styled.img`
  width: 70%;
  height: auto;
  align-self: center; /* Align image horizontally in the center */
  margin-top: auto; /* Push image to the bottom of the block */

  @media (max-width: 876px) {
    margin-top: 2em;
  }
`;


const GridComponent = () => {
  const content = [
    {
      question: 'sevenjackpots.com/br/',
      answer: 'We are very excited to work with Moonster Casino. We’re happy to be starting out with such a powerful partnership and excited to see where it can lead in the future. Highly recommend working with Moonster.',
      img: 'none',
    },
    {
      question: 'igamingaffiliates.io/',
      answer: 'iGamingAffiliates.io’s collaboration with Moonster.com is a game-changer. Moonster.com’s innovative gaming solutions have boosted our affiliate marketing success. Seamless integration, top-notch support, and impressive results make this partnership a standout in the iGaming industry. Highly recommended for affiliates seeking excellence.',
      img: 'none',
    },
    {
      question: 'aviateleads.com/',
      answer: 'We are having a very positive experience working with Moonster Casino. They can count on a valid gaming platform and a very competent and responsive affiliate manager. Highly recommended.',
      img: 'none',
    },
    {
      question: 'askgamblers.com/',
      answer: 'Efficient and productive is how we like to describe our collaboration with Moonster Affiliates. With a partner like Moonster, you can kick back, relax, and pick the fruits of your labour while watching the commission go up!',
      img: '/img/red.png',
    },
    {
      question: 'mount-media.com',
      answer: 'Excited to continue a fruitful partnership with Moonster, highly recommend!',
      img: 'none',
    },
    {
      question: 'betzillion.com/',
      answer: 'Moonster Partners has been one of our very successful partners since day one at BetZillion. The variety of betting options that they have is what makes them unique in this industry. They are, without a doubt, one of the most trustworthy affiliates right now, and that’s for a good reason.',
      img: '/img/green.png',
    },
    {
      question: 'tips.gg/',
      answer: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper',
      img: '/img/tips.png',
    },
    {
      question: 'brasilvegas.com/',
      answer: '"Our partnership with Moonster at BrasilVegas.com has been a game-changer. Their innovative gaming platform and exceptional support team have significantly boosted our offerings and market presence. Moonsters professionalism and dedication to excellence make them an ideal partner in the gaming industry. We highly recommend them for their results-driven approach and reliability."',
      img: 'none',
    },
    {
      question: 'Aboutslots.com/',
      answer: '"Club Affiliates and their unwavering dedication to supporting their affiliates is one of the many reasons Aboutslots.com highly recommends working with them. Their team is knowledgeable and responsive, and we appreciate their promptness in always providing support."',
      img: 'none',
    },
    {
      question: 'nodepositfriend.com',
      answer: 'Partnering with Club Affiliates has been incredibly beneficial for our business. Their responsive support team and comprehensive resources have played a crucial role in enhancing our performance in the gambling sector. We highly recommend Club Affiliates to those looking for a reliable and effective affiliate program.',
      img: '/img/NDF.png',
    },
    {
      question: 'nodeposit365.com',
      answer: 'Working alongside Club Affiliates has been a highly positive experience. Their innovative tools and proactive support have driven our success, helping us achieve significant milestones in the competitive gambling industry. Club Affiliates is a top-tier choice for affiliates aiming to excel.',
      img: '/img/ND365.png',
    },
    {
      question: 'nodepositkings.com',
      answer: 'Our collaboration with Club Affiliates has been exceptionally rewarding. Their user-friendly platform and attentive team have provided us with the essential resources needed to thrive in the gambling market. We confidently endorse Club Affiliates as a premier affiliate program.',
      img: '/img/NDK.png',
    },
    {
      question: 'freespinstracker.com',
      answer: 'Our experience with Club Affiliates has been outstanding. Their dedicated support and advanced resources have been instrumental in our achievements within the gambling industry. Club Affiliates is a trusted partner we are proud to work with.',
      img: '/img/FST.png',
    },
    {
      question: 'bonusmonitor.ca',
      answer: 'Partnering with Club Affiliates has taken our affiliate business to the next level. Their exceptional support and diverse range of tools have been critical in driving our success. We highly recommend Club Affiliates to any affiliate seeking a fruitful and dependable collaboration (Editor in Chief | Roy Carswell)',
      img: '/img/BM-CA.png',
    },
    {
      question: 'https://bestarabiccasinos.com/',
      answer: 'ZeusWin, although a new brand, is a great brands to work with. It covers it all: casino, sport, virtuals, esports and live dealer games. Good choice of payment options and generous promotions just add to that. It also a pleasure working with their team.',
      img: '/img/BAC.png',
    },
    {
      question: 'https://bestbookmakersonline.net/',
      answer: 'If you promote both, casino and bookmakers, you should definitely consider ZeusWin. We have just start working with this brand, and till now have not been disappointed, their affiliate team is everything you are looking for.',
      img: '/img/NET.png',
    },
    {
      question: 'https://elarabcoin.com/',
      answer: 'Club Affiliates offer professionalism, fast communication and customized marketing materials to fit your needs. We highly recommend this affiliate program.',
      img: '/img/ELA.png',
    },
    {
      question: 'https://bonusmaniac.com/',
      answer: 'Today we are happy to share our positive experience with Clubaffiliates! BonusManiac emphasizes that the Clubaffiliates platform offers attractive offers, excellent marketing tools and a friendly atmosphere. We think this is a great choice for you. ',
      img: '/img/freespin.png',
    },
    {
      question: 'https://freespinsworld.com/',
      answer: 'Working with Clubaffiliates has been a pleasant experience for us. FreeSpinsWorld confidently recommends Clubaffiliates to you as a friendly and reliable affiliate program. They offer many advantages, including a high level of technical support and fast processing of applications. ',
      img: '/img/BM.png',
    },
    {
      question: 'https://www.casino-groups.com/',
      answer: 'Club Affiliates is a reliable affiliate program with knowledgeable affiliate managers and very good affiliate support. The commission rates are fair, and the reporting in the back office is very transparent. We are promoting their brand ZeusWin on Casino-Groups.com and are very pleased with the performance. Highly recommended!',
      img: '/img/casinoGroups.svg',
    },
     {
      question: 'https://www.kasinohai.com/',
      answer: "ClubAffiliates are an amazing partner for us at Kasinohai, their brands are super popular amongst our players, their systems are very easy to navigate and the team is a joy to work with! We couldn't be happier - definitely recommended!",
      img: '',
    },
    {
      question: 'https://uusimmatkasinot.com/',
      answer: "Uusimmatkasinot is very pleased with the collaboration with ClubAffiliates, the cooperation has been smooth since the get go, their team is always professional and friendly and their brands are just great, We are excited to see what the future brings!",
      img: '/img/siteLogo.svg',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const titleRef = useRef(null);

  useEffect(() => {
    const title = titleRef.current;

    gsap.fromTo(
      title, 
    { autoAlpha: 0 ,y:20 }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 1, // duration of the animation
      y:0,
      scrollTrigger: {
        trigger: title, // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );


  gsap.fromTo(
    '.testimonial-block',
    { autoAlpha: 0  }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 1, 
      stagger: {
      each: 0.5, // Adjust this value to change the delay between each testimonial block
    },
      scrollTrigger: {
        trigger: '.testimonial-block', // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );

  }, []);

  return (
    <Container id="testimonial">
      <Logo src="/img/testi.png" alt="Logo"  ref={titleRef}/>
      <GridContainer>
        {content.map((item, index) => (
          <TestimonialBlock key={index} className="testimonial-block">
          <a style={{textDecoration:'none'}} href={"http://"+item.question} target="_blank" rel="noopener noreferrer"><Title>{item.question}</Title></a> 
            <Text>{item.answer}</Text>
            {item.img !== 'none' && <Img src={item.img} />}
          </TestimonialBlock>
        ))}
      </GridContainer>
    </Container>
  );
};

export default GridComponent;


